<template>
    <el-container>
        <el-header>
            <HeaderTop />
        </el-header>
        <el-container>
            <el-aside width="200px">
                <LeftMenu />
            </el-aside>
            <el-container>
                <el-main>

                    <div class="contentBox">
                        <div class="contentBox1" style="text-algin:center">
                            <div class="contentBox2">
                                <el-input type="textarea" style="width:500px; margin-bottom:20px" :rows="1" placeholder="请输入代理ip,非必填" v-model="proxyIp"></el-input>
                                <el-input type="textarea" style="width:500px" :rows="5" placeholder="请输入关键词" v-model="inputKeyWords"></el-input>
                                <div>
                                    <el-select v-model="searchType">
                                        <el-option key="百度" label="百度" value="百度"></el-option>
                                        <el-option key="搜狗" label="搜狗" value="搜狗"></el-option>
                                        <el-option key="神马" label="神马" value="神马"></el-option>
                                        <el-option key="360" label="360" value="360"></el-option>
                                    </el-select>
                                    <el-button type="primary" style="margin:20px 20px 0px 20px;width:240px" @click="submit()"> 提交</el-button>
                                </div>

                            </div>

                            <!-- 列表内容 -->
                            <div style="margin-top:20px">
                                <el-table :data="resultList" style="width:500px;" height="500" border stripe>
                                    <el-table-column prop="keyword" label="关键词" align="center" width="250">
                                        <template slot-scope="scope">
                                            <el-button v-if='scope.row.result=="√"' @click="toBaikeCatch(scope.row.keyword)" type="text" size="small" style="color:green">{{scope.row.keyword}}</el-button>
                                            <el-button v-if='scope.row.result=="x"' @click="toBaikeCatch(scope.row.keyword)" type="text" size="small" style="color:red">{{scope.row.keyword}}</el-button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="result" label="结果" align="center" width="250">
                                        <template slot-scope="scope">
                                            <el-button v-if='scope.row.result=="√"' type="text" size="small" style="color:green">{{scope.row.result}}</el-button>
                                            <el-button v-if='scope.row.result=="x"' type="text" size="small" style="color:red">{{scope.row.result}}</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div style="margin-left:50px">
                            <el-input type="textarea" style="width:600px;" :rows="34" placeholder="这里展示查询结果:" v-model="searchResult"></el-input>
                        </div>

                        <div style="margin-left:50px">
                            <el-button type="primary" style="margin:20px 0px;width:260px" @click='toOutResultTxt()'> 导出结果txt </el-button>
                            <el-button type="primary" style="margin:20px 0px;width:260px" @click='toProxyIpAdd()'> 代理ip地址 </el-button>
                            <p>账号: 17681897760</p>
                            <p>密码: shihzong777</p>
                        </div>
                    </div>

                </el-main>
                <el-footer>
                    <Footer />
                </el-footer>
            </el-container>
        </el-container>
        <router-view /><!-- 路由出口 -->
    </el-container>
</template>

<script>
import LeftMenu from '@/components/LeftMenu0.vue'
import HeaderTop from '@/components/Top.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'UserAdd',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            searchType: "",
            proxyIp: "",
            inputKeyWords: "",
            searchResult: "",
            resultList: [],
        }
    },

    created() {
        this.initWebSocket()
    },
    methods: {
        toOutResultTxt() {

            var isIE = (navigator.userAgent.indexOf('MSIE') >= 0);
            var filename = "result.txt"
            var strHTML = "";
            for (let keyOne of this.resultList) {
                strHTML += (keyOne.keyword + "    " + keyOne.result + "    " + "https://www.baidu.com/s?wd=" + keyOne.keyword + "\n")
            }
            if (isIE) {
                var winSave = window.open();
                winSave.document.open("text", "utf-8");
                winSave.document.write(strHTML);
                winSave.document.execCommand("SaveAs", true, filename);
                winSave.close();
            } else {
                var pom = document.createElement('a');
                pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(strHTML));
                pom.setAttribute('download', filename);
                if (document.createEvent) {
                    var event = document.createEvent('MouseEvents');
                    event.initEvent('click', true, true);
                    pom.dispatchEvent(event);
                } else {
                    pom.click();
                }
            }
        },
        toProxyIpAdd() {
            window.open("https://zhimahttp.com/getapi/")
        },

        toBaikeCatch(keyword) {
            window.open("https://www.baidu.com/s?wd=" + keyword)
        },
        submit() {
            if (this.inputKeyWords != "") {
                let reqStr = ""
                let paramArr = this.inputKeyWords.split("\n")
                for (let i = 0; i < paramArr.length; i++) {
                    let item = paramArr[i]
                    if (i != 0) {
                        item = "bs-split" + item.replace(/(^\s*)|(\s*$)/g, "")
                    }
                    reqStr += item
                }
                console.log(reqStr)
                let sendData = {
                    "msg_type": 2, // 1 = 心跳  2 = 查询参数
                    "msg_content": reqStr,
                }

                if (this.proxyIp != "") {
                    sendData.proxy_ip = this.proxyIp
                }
                this.websocketsend(JSON.stringify(sendData))
            } else {
                alert("请输入关键词 ! ")
            }
        },

        initWebSocket() { //初始化weosocket
            const wsuri = "ws://127.0.0.1:9077/tools/cors/baike/catch";
            //  const wsuri = "wss://tools.boshuoit.com/tools/cors/baike/catch";
            this.websock = new WebSocket(wsuri);
            this.websock.onmessage = this.websocketonmessage;
            this.websock.onopen = this.websocketonopen;
            this.websock.onerror = this.websocketonerror;
            this.websock.onclose = this.websocketclose;
            this.websocketKeppAlive();
        },
        websocketKeppAlive() {
            let _self = this
            setInterval(function () {
                let sendData = {
                    "msg_type": 1,
                    "msg_content": "keep alive",
                }
                _self.websocketsend(JSON.stringify(sendData))
            }, 20000)
        },
        websocketonopen() { // 连接建立之后执行send方法发送数据
            let sendData = {
                "msg_type": 1,
                "msg_content": "建立连接成功",
            }
            this.websocketsend(JSON.stringify(sendData))
        },
        websocketonerror() { // 连接建立失败重连
            this.initWebSocket();
        },
        websocketonmessage(e) { // 数据接收
            this.searchResult += (e.data + "\n")
            let resObj = JSON.parse(e.data)
            if (resObj.code == 2) {
                this.resultList.push(resObj)
            }
        },
        websocketsend(Data) { // 数据发送
            this.websock.send(Data);
        },
        websocketclose(e) { // 关闭
            console.log('断开连接', e);
        },
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.contentBox {
    display: flex;
    flex-direction: row;
}

.contentBox1 {
    display: flex;
    flex-direction: column;
}
.contentBox2 {
    display: flex;
    flex-direction: column;
}
</style>
